export const projects = [
  {
    id: 1,
    title: "The shoppies.",
    secondaryTitle: "Shopify challenge 2021",
    date: "April 2023",
    tech: ["React", "Plasmic", "Valtio"],
    description: `Implementation of shopify challenge, while testing out plasmic, valtio and tanstack query.`,
    reminder: false,
    demoLink: `https://shopify-challenge-2021-andrea.vercel.app/`,
    repoLink: `https://github.com/Moonriz-ar/shopify-challenge-2021`,
    imgPath: "/img/project-the-shoppies.png",
  },
  {
    id: 2,
    title: "Github API app.",
    secondaryTitle: "Digital House Frontend III",
    date: "September 2022",
    tech: ["Typescript", "Material UI"],
    description: `Enter a Github username (for example my username: Moonriz-ar), and see the user's bio info and repositories. Practiced using Typescript in a React project, custom hooks, useContext, useCallback.`,
    reminder: false,
    demoLink: "https://gitpanda.vercel.app/login",
    repoLink: "https://github.com/Moonriz-ar/github-api",
    imgPath: "/img/project-github-api.png",
  },
  {
    id: 3,
    title: "Travel blog.",
    secondaryTitle: "Remake concept of my uncle's blog",
    date: "January 2022",
    tech: ["Next.js", "Ghost CMS", "Tailwind"],
    description: `The blog backend uses Ghost headless CMS hosted on Heroku to have an intuitive dashboard. The front uses Next.js hosted on Vercel to consume the APIs provided by Ghost and generate the static files with ISR (Incremental Static Regeneration). It is a responsive website using Tailwind for styling.`,
    reminder: false,
    demoLink: `https://leandro-cheng.vercel.app/`,
    repoLink: `https://github.com/Moonriz-ar/leandro-cheng`,
    imgPath: "/img/project-travel-blog.png",
  },
];
